import { useStaticQuery, graphql } from "gatsby"

const useSpeakerImages = () => {
  const speakers = useStaticQuery(graphql`
    query {
        tate: file(relativePath: { eq: "tate_jacqueline.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        roxas: file(relativePath: { eq: "rachel_roxas.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        beekhuyzen: file(relativePath: { eq: "jenine_beekhuyzen.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        lang: file(relativePath: { eq: "catherine_lang.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        molnar: file(relativePath: { eq: "andreea_molnar.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        trillanes: file(relativePath: { eq: "arlene_trillanes.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        abisado: file(relativePath: { eq: "mideth_abisado.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        ryan: file(relativePath: { eq: "ryan_richard.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        annaliza: file(relativePath: { eq: "annaliza.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        angelique: file(relativePath: { eq: "angelique.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        bernie: file(relativePath: { eq: "bernie.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        jamis: file(relativePath: { eq: "marilou_jamis.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        bimlesh: file(relativePath: { eq: "bimlesh.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
    }
  `)

  return speakers
}

export default useSpeakerImages
