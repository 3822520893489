import React from "react"
import { Link } from "gatsby"
import avatar from "../../images/avatar.jpg"
import Img from "gatsby-image"

const Speaker = ({name, link, position, descriptions, width, img, spacing}) => {
    return (
        <Link to={link} className={`${width || "w-1/4"} ${spacing} pb-2 lg:pr-2`} data-sal="fade" data-sal-easing="ease" data-sal-duration="600">
            <div className="flex flex-col h-full shadow hover:shadow-md transition-all ease-in bg-white rounded overflow-hidden">
                <div className={`${img == null && "flex-1 flex justiy-center items-center"} bg-white`}>
                    {img == null && <img className="mt-6 h-36 mx-auto" src={avatar} alt="avatar" /> }
                    {img != null && <Img className="w-full h-full mx-auto" fluid={img} alt="avatar" /> }
                </div>
                <div className="p-4">
                    <p className="text-center text-sm md:text-xs font-bold text-accent">{position}</p>
                    <h2 className="text-center md:text-base text-xl font-semibold text-primary">{name}</h2>
                    {descriptions != null && descriptions.map(description => {
                        return <h3 className="text-center text-base md:text-sm">{description}</h3>
                    })}
                </div>
            </div>
        </Link>
    )
}

export default Speaker