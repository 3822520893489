import React from "react"

import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"

import Banner from "../components/sections/banner"
import SectionWrapper from "../components/sections/section-wrapper"
import SectionTitle from "../components/sections/section-title"
import Speaker from "../components/sections/speaker"
import Footer from "../components/sections/footer"
import useSpeakerImages from "../hooks/useSpeakerImages"

const Committee = () => {
  const speakerImages = useSpeakerImages()
  const acmwapCommittee = [
    {
      name: "Jacqueline Tate",
      position: "Chair, ACM-W AP",
      img: speakerImages.tate.childImageSharp.fluid,
      descriptions: ["Australia"],
    },
    {
      name: "Rachel Edita O. Roxas",
      position: "Chair, Celebration",
      img: speakerImages.roxas.childImageSharp.fluid,
      descriptions: ["Philippines"],
    },
    {
      name: "Andreea Molnar",
      position: "Chair, Communications",
      img: speakerImages.molnar.childImageSharp.fluid,
      descriptions: ["Australia"],
    },
    {
      name: "Catherine Lang",
      position: "Vice-Chair, Communications",
      img: speakerImages.lang.childImageSharp.fluid,
      descriptions: ["Australia"],
    },
    {
      name: "Dr. Bimlesh Wadhwa",
      position: "Chair, Program and Chair, Shining and Rising Star Awards",
      img: speakerImages.bimlesh.childImageSharp.fluid,
      descriptions: ["Singapore"],
    },
    {
      name: "Jenine Bekkhuyzen",
      position: null,
      img: speakerImages.beekhuyzen.childImageSharp.fluid,
      descriptions: ["Australia"],
    },
    {
      name: "Saw Sandra Aye",
      position: null,
      img: null,
      descriptions: ["Myanmar"],
    },
    {
      name: "Jane Yung",
      position: null,
      img: null,
      descriptions: ["China"],
    },
  ]

  const celebrationCommittee = [
    {
      name: "Rachel Edita O. Roxas",
      position: "Chair, Celebration 2021",
      img: speakerImages.roxas.childImageSharp.fluid,
      descriptions: ["Philippines"],
    },
  ]

  const programsCommittee = [
    {
      name: "Dr. Bimlesh Wadhwa",
      position: "Chair",
      img: speakerImages.bimlesh.childImageSharp.fluid,
      descriptions: ["National University of Singapore"],
    },
    {
      name: "Jacqueline Tate",
      position: "Vice-Chair",
      img: speakerImages.tate.childImageSharp.fluid,
      descriptions: ["Australia"],
    },
  ]

  const programsCommitteeTable = [
    {
      name: "Angelique Lacasandile",
      organization: "National University, Manila",
      country: "Philippines"
    },
    {
      name: "Arlene Trillanes",
      organization: "National University, Manila",
      country: "Philippines"
    },
    {
      name: "Charibeth Cheng",
      organization: "De La Salle University, Manila",
      country: "Philippines"
    },
    {
      name: "Cherry Lyn Sta. Romana",
      organization: "Cebu Institute of Technology University ",
      country: "Philippines"
    },
    {
      name: "Jenine Bekkhuyzen",
      organization: "Tech Girls Movement",
      country: "Australia"
    },
    {
      name: "Julie Ann Salido",
      organization: "Aklan State University",
      country: "Philippines"
    },
    {
      name: "Lany Laguna-Maceda",
      organization: "Bicol University",
      country: "Philippines"
    },
    {
      name: "Ma. Mercedes T. Rodrigo",
      organization: "Ateneo De Manila University",
      country: "Philippines"
    },
    {
      name: "Maria Art Antonette Clariño",
      organization: "University of the Philippines, Los Baños ",
      country: "Philippines"
    },
    {
      name: "Mei Kobayashi",
      organization: "Tsuda University",
      country: "Japan"
    },
    {
      name: "Nova Ahmed",
      organization: "North South University",
      country: "Bangladesh"
    },
    {
      name: "Pin Pin Tea-Makorn",
      organization: "Sasin School of Management",
      country: "Thailand"
    },
    {
      name: "Pornsiri Muenchaisri",
      organization: "Chulalongkorn University",
      country: "Thailand"
    },
    {
      name: "Rachel Edita O. Roxas",
      organization: "National University, Manila, Philippines",
      country: "Philippines"
    },
    {
      name: "Susan Pancho",
      organization: "University of the Philippines ",
      country: "Philippines"
    },
    {
      name: "Thelma Palaoag",
      organization: "University of the Cordilleras",
      country: "Philippines"
    },
  ]

  const technicalCommittee = [
    {
      name: "Arlene O. Trillanes",
      position: "Chair",
      img: speakerImages.trillanes.childImageSharp.fluid,
      descriptions: ["National University, Manila, Philippines"],
    },
    {
      name: "Mideth Abisado",
      position: "Member",
      img: speakerImages.abisado.childImageSharp.fluid,
      descriptions: ["National University, Manila, Philippines"],
    },
    {
      name: "Ryan Richard H. Guadaña",
      position: "Member",
      img: speakerImages.ryan.childImageSharp.fluid,
      descriptions: ["National University, Manila, Philippines"],
    },
    {
      name: "Annaliza E. Catacutan-Bangit",
      position: "Member",
      img: speakerImages.annaliza.childImageSharp.fluid,
      descriptions: ["National University, Manila, Philippines"],
    },
    {
      name: "Angelique Lacasandile",
      position: "Member",
      img: speakerImages.angelique.childImageSharp.fluid,
      descriptions: ["National University, Manila, Philippines"],
    },
    {
      name: "Bernie Fabito",
      position: "Member",
      img: speakerImages.bernie.childImageSharp.fluid,
      descriptions: ["National University, Manila, Philippines"],
    },
    {
      name: "Marilou N. Jamis",
      position: "Member",
      img: speakerImages.jamis.childImageSharp.fluid,
      descriptions: ["National University, Manila, Philippines"],
    },
  ]

  return (
    <Layout>
      <Navigation />
      <Banner title="Organizing Committee" />
      <SectionWrapper id="acmwap">
        <SectionTitle>ACMW – Asia Pacific Committee Members</SectionTitle>
        <div className="flex flex-wrap items-stretch mt-4">
          {acmwapCommittee.map((d, i) => (
            <Speaker
              width="w-full md:w-1/3 lg:w-1/4"
              spacing="md:pr-2"
              name={d.name}
              position={d.position}
              img={d.img}
              descriptions={d.descriptions}
            />
          ))}
        </div>
      </SectionWrapper>
      <SectionWrapper id="celebration">
        <SectionTitle>Celebration 2021 Committee</SectionTitle>
        <div className="flex flex-wrap items-stretch mt-4">
          {celebrationCommittee.map((d, i) => (
            <Speaker
              width="w-full md:w-1/3 lg:w-1/4"
              spacing="md:pr-2"
              name={d.name}
              position={d.position}
              img={d.img}
              descriptions={d.descriptions}
            />
          ))}
        </div>
      </SectionWrapper>
      <SectionWrapper id="program">
        <SectionTitle>Program Committee</SectionTitle>
        <div className="flex flex-wrap items-stretch mt-4">
          {programsCommittee.map((d, i) => (
            <Speaker
              width="w-full md:w-1/3 lg:w-1/4"
              spacing="md:pr-2"
              name={d.name}
              position={d.position}
              img={d.img}
              descriptions={d.descriptions}
            />
          ))}
        </div>
        <div className="mt-4">
          <div className={"w-full overflow-auto mb-10"}>
            <table className="min-w-full w-full table-fixed border-2 border-gray-800" style={{minWidth: "768px"}}>
              <thead className="justify-between">
              <tr className="bg-gray-800">
                <th className="w-4/12 px-4 py-2 text-gray-300 text-left align-top">Name</th>
                <th className="w-5/12 px-4 py-2 text-gray-300 text-left align-top">Organization</th>
                <th className="w-3/12 px-4 py-2 text-gray-300 text-left align-top">Country</th>
              </tr>
              </thead>
              <tbody className="bg-gray-200">
                {programsCommitteeTable.map((d, i) => (
                  <tr className={i % 2 !== 0? "bg-gray-200" : "bg-white"}>
                    <td className="px-4 py-2 align-top">{d.name}</td>
                    <td className="px-4 py-2 align-top">{d.organization}</td>
                    <td className="px-4 py-2 align-top">{d.country}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper id="technical">
        <SectionTitle>Technical Committee</SectionTitle>
        <div className="flex flex-wrap items-stretch mt-4">
          {technicalCommittee.map((d, i) => (
            <Speaker
              width="w-full md:w-1/3 lg:w-1/4"
              spacing="md:pr-2"
              name={d.name}
              position={d.position}
              img={d.img}
              descriptions={d.descriptions}
            />
          ))}
        </div>
      </SectionWrapper>
      <Footer />
    </Layout>
  )
}

export default Committee
